<template>
    <div style="padding: 200px 0 50px 0;display: grid;place-content: center;">
      <div class="container">
        <div class="row">
        <div class="col-md-4">
              <img class="w-100" src="https://etmaam.com.sa/assets/front/img/404.png" alt="">
        </div>
        <div class="col-md-8" style="display: grid;place-content: center;">
       <h1 style="color: #052C57;">
        {{ $t('page_not_found') }}
       
       </h1>
        </div>
      </div>
      </div>
    </div>
</template>
<script>
export default {
    name: 'Error Page',
    mwthods:{
      handleStaticAbout(StaticContent){
    StaticContent.forEach((item) => {
    if (item.key == 'about_etmaam') {
      thi
s.title = item.value;
    }
    if(item.key == 'about_etmaam_description'){
      this.description = item.value;
    }
    if(item.key == 'about_etmaam_photo'){
      this.img = item.value;
    }
    if(item.key == 'card_1_title'){
      this.card_1_title = item.value;
    }
    if(item.key == 'card_1_description'){
      this.card_1_description = item.value;
    }
    if(item.key == 'card_1_photo'){
      this.card_1_photo = item.value;
    }
    if(item.key == 'card_2_title'){
      this.card_2_title = item.value;
    }
    if(item.key == 'card_2_description'){
      this.card_2_description = item.value;
    }
    if(item.key == 'card_2_photo'){
      this.card_2_photo = item.value;
    }
    if(item.key == 'card_3_title'){
      this.card_3_title = item.value;
    }
    if(item.key == 'card_3_description'){
      this.card_3_description = item.value;
    }
    if(item.key == 'card_3_photo'){
      this.card_3_photo = item.value;
    }
  });
    },
    },
    mounted() {
    this.$store.dispatch('GetStaticContent').then(r => {
          this.handleStaticAbout(this.$store.state.StaticContent);
    });
  }
}
</script>
